// @ts-ignore: @reach/router types
import { Link, useLocation } from '@reach/router';
import React, { useReducer } from 'react';
import AppsIcon from '@material-ui/icons/Apps';
import Box from '@material-ui/core/Box';
import GavelIcon from '@material-ui/icons/Gavel';
import NavigationItem from './NavigationItem';
import NotificationsIcon from '@material-ui/icons/Notifications';
import SettingsIcon from '@material-ui/icons/Settings';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import NotificationsDrawer from '../Notifications';
import AppsMenu from '../AppsMenu';
import SettingsMenu from '../SettingsMenu';
import ApiSecureLogo from './apiSecureLogo.svg';
import MobileSecureLogo from './mobileSecureLogo.svg';
var Navigation = function () {
    var location = useLocation();
    var isApi = !!location.pathname.match(/api\/v2/);
    var isMobile = !!location.pathname.match(/mobile-secure\/v2/);
    var isDevSecOps = !!location.pathname.match(/devsecops\/v2/);
    var isManagement = !!location.pathname.match(/management\/v2/);
    var _a = React.useState(null), anchorSettings = _a[0], setAnchorSettings = _a[1];
    var _b = React.useState(null), anchorApps = _b[0], setAnchorApps = _b[1];
    var _c = useReducer(function (v) { return !v; }, false), expandedNotifications = _c[0], toggleExpandedNotifications = _c[1];
    if (!isApi && !isMobile && !isDevSecOps && !isManagement)
        return null;
    var baseUrl = '/api/v2';
    if (isMobile) {
        baseUrl = '/mobile-secure/v2';
    }
    else if (isDevSecOps || isManagement) {
        baseUrl = '/api/v2'; // TMP Workaround
    }
    return (React.createElement(Box, { display: "flex", justifyContent: "space-between", style: {
            background: '#F4F5FC',
            padding: 24,
        } },
        expandedNotifications && (React.createElement(NotificationsDrawer, { isOpen: expandedNotifications, toggle: toggleExpandedNotifications })),
        React.createElement(AppsMenu, { isOpen: Boolean(anchorApps), anchorEl: anchorApps, handleClose: function () {
                setAnchorApps(null);
            } }),
        React.createElement(SettingsMenu, { isOpen: Boolean(anchorSettings), anchorEl: anchorSettings, handleClose: function () {
                setAnchorSettings(null);
            } }),
        React.createElement(Box, { display: "grid", alignItems: "center", gridTemplateColumns: "repeat(7, auto)", gridGap: 12, style: {
                background: '#F4F5FC',
            } },
            React.createElement(Link, { to: '/dashboard' },
                React.createElement("svg", { width: "45", height: "45", viewBox: "0 0 45 45", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
                    React.createElement("path", { d: "M45 35.7895C45 40.8772 40.8745 45 35.7835 45H9.21651C4.12549 45 0 40.8772 0 35.7895V9.23976C0 4.15204 4.12549 0 9.21651 0H35.7835C40.8745 0 45 4.15204 45 9.23976V35.7895Z", fill: "#123770" }),
                    React.createElement("path", { d: "M31.7165 19.0059V24.7953C31.7165 26.3743 32.2724 27.7486 33.4135 28.8889C34.5546 30 35.9005 30.5556 37.5098 30.5556V34.8831C34.7009 34.8831 32.331 33.9182 30.3414 31.9299C28.381 29.9708 27.3862 27.6024 27.3862 24.7953V8.91815H31.7165V14.6784H37.5098V19.0059H31.7165Z", fill: "white" }),
                    React.createElement("path", { d: "M18.4915 28.8597C19.6326 27.7486 20.1885 26.3743 20.1885 24.7953C20.1885 23.1872 19.6326 21.8421 18.4915 20.7018C17.3797 19.5907 16.0045 19.0059 14.4246 19.0059C12.8153 19.0059 11.4694 19.5907 10.3283 20.7018C9.2165 21.8421 8.63133 23.1872 8.63133 24.7953C8.63133 26.3743 9.2165 27.7486 10.3283 28.8597C11.4694 30 12.8153 30.5556 14.4246 30.5556C16.0045 30.5556 17.3797 30 18.4915 28.8597ZM24.5188 24.7953C24.5188 27.6024 23.5533 29.9708 21.5637 31.9299C19.6034 33.9182 17.2041 34.8831 14.4246 34.8831C11.6157 34.8831 9.24576 33.9182 7.25616 31.9299C5.29583 29.9708 4.30103 27.6024 4.30103 24.7953C4.30103 21.9883 5.29583 19.6199 7.25616 17.6316C9.24576 15.6725 11.6157 14.6784 14.4246 14.6784C16.5897 14.6784 18.5208 15.2924 20.1885 16.462V8.91815H24.5188V24.7953Z", fill: "white" }))),
            isApi && React.createElement("img", { src: ApiSecureLogo, alt: "api-secure-logo" }),
            isMobile && React.createElement("img", { src: MobileSecureLogo, alt: "mobile-secure-logo" }),
            React.createElement(NavigationItem, { to: "".concat(baseUrl, "/dashboard"), label: "Dashboard" }),
            React.createElement(NavigationItem, { to: "/api/v2/apps", label: "Apps", isButton: true, Icon: AppsIcon, onClick: function (event) {
                    setAnchorApps(event.currentTarget);
                } }),
            React.createElement(NavigationItem, { to: "".concat(baseUrl, "/inventory/").concat(isMobile ? 'apps' : 'api'), label: "Inventory", baseUrl: "".concat(baseUrl, "/inventory") }),
            React.createElement(NavigationItem, { to: "".concat(baseUrl, "/security/hack-me-amadeus"), label: "Security", baseUrl: "".concat(baseUrl, "/security") }),
            React.createElement(NavigationItem, { to: "".concat(baseUrl, "/active-protection/overview"), label: "Active Protection", baseUrl: "".concat(baseUrl, "/active-protection") })),
        React.createElement(Box, { display: "grid", alignItems: "center", gridTemplateColumns: "repeat(5, 1fr)", gridGap: 12, style: {
                justifySelf: 'flex-end',
            } },
            React.createElement(NavigationItem, { baseUrl: "".concat(baseUrl, "/policies"), to: "".concat(baseUrl, "/policies"), label: "Policies", Icon: GavelIcon }),
            React.createElement(NavigationItem, { to: "".concat(baseUrl, "/notifications"), label: "Notifications", Icon: NotificationsIcon, isButton: true, onClick: toggleExpandedNotifications }),
            React.createElement(NavigationItem, { to: "/devsecops/v2/stanford-dish-security-university", baseUrl: "/devsecops/v2", label: "Security Training", Icon: React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
                    React.createElement("path", { d: "M3.90039 9.30078H9.70117V7.85058H3.90039V9.30078Z", fill: "currentColor" }),
                    React.createElement("path", { d: "M9.70117 12.2012H3.90039V10.751H9.70117V12.2012Z", fill: "currentColor" }),
                    React.createElement("path", { d: "M9.70117 15.1016H3.90039V13.6514H9.70117V15.1016Z", fill: "currentColor" }),
                    React.createElement("path", { d: "M19.8525 9.30078H14.0518V7.85058H19.8525V9.30078Z", fill: "currentColor" }),
                    React.createElement("path", { d: "M14.0518 12.2012H19.8525V10.751H14.0518V12.2012Z", fill: "currentColor" }),
                    React.createElement("path", { d: "M14.0518 15.1016H19.8525V13.6514H14.0518V15.1016Z", fill: "currentColor" }),
                    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M1.7251 3.5C1.32464 3.5 1 3.82464 1 4.2251V18.0019C1 18.4024 1.32464 18.727 1.7251 18.727H9.70117C10.5021 18.727 11.1514 19.3763 11.1514 20.1772C11.1514 20.5777 11.476 20.9023 11.8765 20.9023C12.2769 20.9023 12.6016 20.5777 12.6016 20.1772C12.6016 19.3763 13.2508 18.727 14.0518 18.727H22.0278C22.4283 18.727 22.7529 18.4024 22.7529 18.0019V4.2251C22.7529 3.82464 22.4283 3.5 22.0278 3.5H14.0518C13.1855 3.5 12.4079 3.87977 11.8765 4.48191C11.345 3.87977 10.5674 3.5 9.70117 3.5H1.7251ZM11.1514 6.40039V17.6649C10.7248 17.4181 10.2295 17.2769 9.70117 17.2769H2.45019V4.95019H9.70117C10.5021 4.95019 11.1514 5.59947 11.1514 6.40039ZM14.0518 17.2769C13.5235 17.2769 13.0282 17.4181 12.6016 17.6649V6.40039C12.6016 5.59947 13.2508 4.95019 14.0518 4.95019H21.3027V17.2769H14.0518Z", fill: "currentColor" })), isSvg: true }),
            React.createElement(NavigationItem, { to: "/api/v2/devsecops", baseUrl: "/api/v2/devsecops", label: "DevSecOps", Icon: TrackChangesIcon }),
            React.createElement(NavigationItem, { to: '/management/v2/settings', baseUrl: '/management/v2', label: "Settings", Icon: SettingsIcon, isButton: true, onClick: function (event) {
                    setAnchorSettings(event.currentTarget);
                } }))));
};
export default Navigation;
